<template>
  <div class="SecurityCodeInputComponent d-flex">
    <MuiInputText
      v-model="character1"
      v-validate="{
        isRequired: {
          message: ' ',
        },
      }"
      data-test-id="security-code-input-1"
      name="MuiInputText-1"
      class="SecurityCodeInputComponent__input d-inline-block mr-3"
      maxlength="1"
      size="large"
      autofocus
      @input="onChangeInput(1, $event)"
      @paste="onPaste"
    />
    <MuiInputText
      v-model="character2"
      v-validate="{
        isRequired: {
          message: ' ',
        },
      }"
      data-test-id="security-code-input-2"
      name="MuiInputText-2"
      class="SecurityCodeInputComponent__input d-inline-block mr-3"
      maxlength="1"
      size="large"
      @input="onChangeInput(2, $event)"
      @paste="onPaste"
    />
    <MuiInputText
      v-model="character3"
      v-validate="{
        isRequired: {
          message: ' ',
        },
      }"
      data-test-id="security-code-input-3"
      name="MuiInputText-3"
      class="SecurityCodeInputComponent__input d-inline-block mr-3"
      maxlength="1"
      size="large"
      @input="onChangeInput(3, $event)"
      @paste="onPaste"
    />
    <MuiInputText
      v-model="character4"
      v-validate="{
        isRequired: {
          message: ' ',
        },
      }"
      data-test-id="security-code-input-4"
      name="MuiInputText-4"
      class="SecurityCodeInputComponent__input d-inline-block"
      maxlength="1"
      size="large"
      @input="onChangeInput(4, $event)"
      @paste="onPaste"
    />
  </div>
</template>
<script>
import { MuiInputText, Validate } from '@emobg/motion-ui/v1';

export default {
  name: 'SecurityCodeInputComponent',
  components: {
    MuiInputText,
  },
  directives: {
    Validate,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    validateForm: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      character1: this.value ? this.value[0] : '',
      character2: this.value ? this.value[1] : '',
      character3: this.value ? this.value[2] : '',
      character4: this.value ? this.value[3] : '',
    };
  },
  methods: {
    onChangeInput(index, value) {
      this[`character${index}`] = value;
      this.updateModelValue();
      this.$nextTick(() => this.validateForm());
    },
    onPaste(event) {
      if (!event) {
        return;
      }

      const text = event.clipboardData && event.clipboardData.getData('text');
      this.character1 = text[0] || '';
      this.character2 = text[1] || '';
      this.character3 = text[2] || '';
      this.character4 = text[3] || '';
      this.updateModelValue();
      this.$nextTick(() => this.validateForm());
    },
    updateModelValue() {
      const modelValue
        = `${this.character1 || ' '}${this.character2 || ' '}${this.character3 || ' '}${this.character4 || ' '}`;
      this.$emit('change', modelValue);
    },
  },
};
</script>
<style lang="scss">
  @import "./SecurityCodeInputComponent";
</style>
