<template>
  <section class="UserEmailVerificationView ecod-gradient-background">
    <NotificationListManager class="UserEmailVerificationView__notification position-fixed" />

    <div class="UserEmailVerificationView__image-wrapper position-absolute h-100 w-100">
      <div class="position-relative w-100 h-100">
        <img
          :src="bgImage"
          class="UserEmailVerificationView__image position-absolute h-100"
          alt="Background image"
        >
      </div>
    </div>
    <MuiValidationWrapper ref="MuiValidationWrapper">
      <template slot-scope="{ areAllValid }">
        <ui-modal
          open
          :header="$t('user_email_verification.title')"
          :size="SIZES.small"
        >
          <div slot="body">
            <p class="emobg-body-small pb-4">
              {{ $t('user_email_verification.text') }}
            </p>
            <div class="pb-4 px-sm-6">
              <SecurityCodeInputComponent
                v-model="code"
                :validate-form="() => $refs.MuiValidationWrapper.validateAll()"
                @change="onChangeSecurityCode"
              />
              <p class="emobg-body-x-small emobg-color-danger">
                {{ hasApiError ? errorMessage : '&#8203;' }}
              </p>
            </div>
            <div class="d-flex">
              <span
                class="emobg-font-weight-bold cursor-pointer text-underline emobg-color-primary-dark"
                @click="onClickResendCode"
              >
                {{ $t('user_email_verification.resend_code') }}
              </span>
            </div>
          </div>
          <div
            slot="footer"
            class="d-flex align-items-center justify-content-center"
          >
            <ui-button
              v-bind="fetchButtonSpecs()"
              data-test-id="email-verification-submit-button"
              :disabled="isSubmitButtonDisabled(areAllValid)"
              :loading="submitStatus.LOADING"
              @clickbutton="onSubmitCode"
            >
              {{ $t('user_email_verification.button_text') }}
            </ui-button>
          </div>
        </ui-modal>
      </template>
    </MuiValidationWrapper>

    <Logo
      :variant="logoContrast"
      class="UserEmailVerificationView__logo position-absolute"
      alt="logo"
    />

    <ui-button
      v-bind="fetchButtonSpecs()"
      :color="GRAYSCALE.white"
      :face="FACES.transparent"
      data-test-id="email-verification-logout-button"
      class="UserEmailVerificationView__logout position-absolute"
      @clickbutton="onLogout"
    >
      {{ $t('user_email_verification.log_out') }}
    </ui-button>
  </section>
</template>

<script>
import get from 'lodash/get';
import set from 'lodash/set';

import { mapActions, mapGetters, mapState } from 'vuex';
import { navigationErrorHandler } from '@emobg/web-utils';
import { MuiValidationWrapper } from '@emobg/motion-ui/v1';

import { nameSpace as UserDataNameSpace } from '@/vue/stores/UserData/UserDataStore';

import { nameSpace as LoaderNamespace } from '@Shared/store/Loader/LoaderStore';
import SharedRouteNames from '@Shared/router/routes-names';

import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import NotificationListManager from '@/components/NotificationList/NotificationListManager';
import Logo from '@/components/Logo/Logo';

import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { ASSETS_CATEGORY, LOGOS_KEYNAMES, PICTURES_KEYNAMES } from '@/constants/assetsRepository';
import { useTheme } from '@/composable/Theme/useTheme';

import SecurityCodeInputComponent from './components/SecurityCodeInput/SecurityCodeInputComponent';
import * as UserEmailVerificationModule from './store/UserEmailVerificationModule';

export default {
  name: 'UserEmailVerificationView',
  components: {
    MuiValidationWrapper,
    SecurityCodeInputComponent,
    NotificationListManager,
    Logo,
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();
    const { notifyError, notifySuccess } = useNotifications();
    const bgImage = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.PICTURES, PICTURES_KEYNAMES.MFA_BACKGROUND);
    const logoContrast = LOGOS_KEYNAMES.CONTRAST_LOGO;
    return {
      fetchButtonSpecs,
      bgImage,
      logoContrast,
      notifyError,
      notifySuccess,
    };
  },
  data() {
    return {
      isSecurityCodeChanged: false,
      hasApiError: false,
      code: '',
    };
  },
  computed: {
    ...mapState(UserEmailVerificationModule.NAMESPACE, {
      submitStatus: state => get(state, 'submitEmail.STATUS'),
      submitError: state => get(state, 'submitEmail.error'),
      resendStatus: state => get(state, 'resendEmail.STATUS'),
    }),
    ...mapGetters(UserDataNameSpace, {
      userData: 'getUserData',
    }),
    errorMessage() {
      const isExpiredCode = this.submitError === 'user_company.email_verification_expired';

      return isExpiredCode ? this.$t('user_email_verification.email_verification_expired') : this.$t('user_email_verification.api_error');
    },
  },
  beforeCreate() {
    this.$store.registerModule(UserEmailVerificationModule.NAMESPACE, UserEmailVerificationModule.store);
  },
  created() {
    this.toggleLoaderStatus(false);
  },
  beforeDestroy() {
    this.$store.unregisterModule(UserEmailVerificationModule.NAMESPACE);
  },
  methods: {
    ...mapActions(UserEmailVerificationModule.NAMESPACE, ['submitVerificationEmail', 'resendVerificationEmail']),
    ...mapActions(LoaderNamespace, ['toggleLoaderStatus']),
    onChangeSecurityCode() {
      this.isSecurityCodeChanged = true;
      this.hasApiError = false;
    },
    async onSubmitCode() {
      const userUUID = this.userData.getUUID();
      await this.submitVerificationEmail({
        code: this.code,
        userUUID,
      });
      if (this.submitStatus.LOADED) {
        this.onSuccessVerificationEmail();
      }
      if (this.submitStatus.ERROR) {
        this.onErrorEmailVerification();
      }
    },
    onSuccessVerificationEmail() {
      set(window, 'location', '/');
    },
    onErrorEmailVerification() {
      this.hasApiError = true;
    },
    async onClickResendCode() {
      if (this.resendStatus.LOADING) {
        return;
      }

      const userUUID = this.userData.getUUID();
      await this.resendVerificationEmail({
        userUUID,
      });
      if (this.resendStatus.LOADED) {
        this.onSuccessResendCode();
      }
      if (this.resendStatus.ERROR) {
        this.onErrorResendCode();
      }
    },
    onSuccessResendCode() {
      this.notifySuccess({
        text: this.$t('user_email_verification.success_resend_code'),
        textAction: this.$t('buttons.ok'),
      });
    },
    onErrorResendCode() {
      this.notifyError({
        text: this.$t('user_email_verification.error_resend_code'),
        textAction: this.$t('buttons.ok'),
      });
    },
    isSubmitButtonDisabled(areAllValid) {
      return !areAllValid || !this.isSecurityCodeChanged || this.submitStatus.LOADING || this.hasApiError;
    },
    onLogout() {
      this.$router.push(SharedRouteNames.logout).catch(navigationErrorHandler);
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "./UserEmailVerificationView";
</style>
