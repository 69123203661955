import {
  INITIAL_STATUS,
  STORE_REQUEST_STATUS,
  setError,
  setStatus,
} from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

import isString from 'lodash/isString';
import get from 'lodash/get';

export const NAMESPACE = 'UserEmailVerificationModule';
export const MUTATIONS = {
  setStatus: 'setStatus',
  setError: 'setError',
};
export const ACTIONS = {
  submitVerificationEmail: 'submitVerificationEmail',
  resendVerificationEmail: 'resendVerificationEmail',
};

const SCOPES = {
  submitEmail: 'submitEmail',
  resendEmail: 'resendEmail',
};

const mutations = {
  setStatus,
  setError,
};
const state = {
  [SCOPES.submitEmail]: {
    STATUS: { ...INITIAL_STATUS },
    error: '',
  },
  [SCOPES.resendEmail]: {
    STATUS: { ...INITIAL_STATUS },
  },
};
const actions = {
  async [ACTIONS.submitVerificationEmail]({ commit }, {
    code,
    userUUID,
  }) {
    if (!isString(code) || !isString(userUUID)) {
      return;
    }
    commit(MUTATIONS.setStatus, {
      value: STORE_REQUEST_STATUS.loading,
      scope: SCOPES.submitEmail,
    });

    try {
      const response = await external.userCompany.putVerificationEmail(userUUID, code);
      if (!get(response, 'email_verified')) {
        commit(MUTATIONS.setStatus, {
          value: STORE_REQUEST_STATUS.error,
          scope: SCOPES.submitEmail,
        });
        return;
      }
      commit(MUTATIONS.setStatus, {
        value: STORE_REQUEST_STATUS.loaded,
        scope: SCOPES.submitEmail,
      });
    } catch (error) {
      commit(MUTATIONS.setStatus, {
        value: STORE_REQUEST_STATUS.error,
        scope: SCOPES.submitEmail,
      });
      commit(MUTATIONS.setError, {
        value: get(error, 'response.data.key'),
        scope: SCOPES.submitEmail,
      });
    }
  },
  async [ACTIONS.resendVerificationEmail]({ commit }, { userUUID }) {
    if (!isString(userUUID)) {
      return;
    }
    commit(MUTATIONS.setStatus, {
      value: STORE_REQUEST_STATUS.loading,
      scope: SCOPES.resendEmail,
    });
    try {
      const response = await external.userCompany.putResendVerificationEmail(userUUID);
      if (!response.email_sent) {
        commit(MUTATIONS.setStatus, {
          value: STORE_REQUEST_STATUS.error,
          scope: SCOPES.resendEmail,
        });
        return;
      }
      commit(MUTATIONS.setStatus, {
        value: STORE_REQUEST_STATUS.loaded,
        scope: SCOPES.resendEmail,
      });
    } catch (error) {
      commit(MUTATIONS.setStatus, {
        value: STORE_REQUEST_STATUS.error,
        scope: SCOPES.resendEmail,
      });
    }
  },
};
export const store = {
  namespaced: true,
  actions,
  mutations,
  state,
};
